<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
export default {
    name: 'app',
    data() {
        return {
            keepAlive: true,
        };
    },
    mounted() {
        this.fun();
    },
    methods: {
        fun: function () {},
    },
};
</script>
<style lang="less"></style>


