import request from "@/utils/request";

export function getHomeMsgList(data) {
  return request.get("/home/homeList", {
    params: data,
  });
}

export function getUser(data) {
  return request.post("/user/userdata", {
    params: data,
  });
}

export function getHomeAttentionList(data) {
  return request.get("/home/attentionList", {
    params: data,
  });
}
