<template>
  <!-- 封装voide -->
  <div class="biseVideo">
    <!-- 视频 -->
    <div class="setVideo" @touchstart="switchPlay()">
      <span v-show="isPlay" class="iconfont icon-bofang suspend"></span>
      <video ref="video" class="video-js vjs-big-play-centered"></video>
    </div>
    <div class="video-progressBar" ref="videoProgressBar">
      <!--进度条 canDrag  nonotDrag-->
      <van-slider
        :class="isCan ? 'canDrag' : 'nonotDrag'"
        v-model="progressBarVal"
        @change="dragProgressBar"
        @drag-start="dragStart"
        @drag-end="dragEnd"
      />
    </div>
  </div>
</template>

<script>
import videojs from "video.js";
export default {
  components: {},
  props: {
    srcUrl: String,
  },
  data() {
    return {
      player: "", //存储当前video
      isPlay: false, // 暂停按钮
      progressBarVal: 0, //当前进度百分比
      isDrag: false, //是否拖拽
      isCan: false,
      progressBarTime: null, //进度条class修改
      screenQidth: window.screen.width, //屏幕宽高
      screenHeight: window.screen.height,
      playerHeight:0,
      playerWidth:0,
    };
  },
  created() {},
  beforeMount() {},
  mounted() {
    this.playerWidth = document.documentElement.clientWidth||document.body.clientWidth
    this.playerHeight = parseInt(document.documentElement.clientHeight||document.body.clientHeight) -50
    this.initVideo();
  },
  wath: {},
  methods: {
    // 初始化video
    initVideo: function () {
      console.log(this.playerWidth ,"playerWidth");
      console.log(this.playerHeight,"playerHeight");
      let options = {
        width: this.playerWidth,
        height: this.playerHeight,
        // fluid: true, //自适应
        // aspectRatio: "1:1", //将播放器置于流体模式下，计算播放器动态大小时使用该值
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        // techOrder: ["html5", "flash"], // 优先使用html5播放器
        autoplay: true, //自动播放
        sources: [
          {
            src: this.srcUrl,
          },
        ],
      };
      const hist = this;
      this.player = videojs(this.$refs.video, options, function () {
        // 播放时间改变
        this.on("timeupdate", function () {
          let duration = this.duration();
          let currntTime = this.currentTime();
          hist.progressBarVal = (currntTime / duration) * 100;
        });
      });
    },
    switchPlay() {
      // 播放与暂停
      if (this.isPlay) {
        this.player.play();
        this.isPlay = false;
      } else {
        this.player.pause();
        this.isPlay = true;
      }
    },
    // 全屏
    fullScreen() {},
    //拖拽进度条
    dragProgressBar(currnetVal) {
      this.player.currentTime((currnetVal / 100) * this.player.duration());
    },
    //开始拖拽
    dragStart() {
      this.isDrag = true;
      this.isCan = true;
      this.player.pause();

      if (this.progressBarTime !== null) {
        clearTimeout(this.progressBarTime);
      }
      const hist = this;
      this.progressBarTime = setTimeout(function () {
        hist.isCan = false;
        clearTimeout(hist.progressBarTime);
        hist.progressBarTime = null;
      }, 3000);
    },
    //结束拖拽
    dragEnd() {
      // 如果暂停键在， 就取消
      if (this.isPlay) {
        this.isPlay = false;
      }
      this.player.play();
    },
  },
  beforeDestroy: function () {
    const videoEl = this.$refs.video;
    videojs(videoEl).dispose();
  },
};
</script>

<style lang="less">
.biseVideo {
  width: 100%;
  height: 100%;
  background-color: #000;

  // 进度条
  .video-progressBar {
    margin: 0 5%;
    position: fixed;
    bottom: 46px;
    left: 0;
    right: 0;
    z-index: 9999;

    // 可拖拽进度条 canDrag
    .canDrag.van-slider {
      height: 2px;
      background: rgba(255, 255, 255, 0.3);
    }
    .canDrag .van-slider__bar {
      background: rgba(255, 255, 255, 0.8);
    }

    .canDrag .van-slider__button {
      width: 12px;
      height: 12px;
    }
    // 不可拖拽进度条
    .nonotDrag.van-slider {
      height: 2px;
      background: rgba(255, 255, 255, 0.2);
    }
    .nonotDrag .van-slider__bar {
      background: rgba(255, 255, 255, 0.4);
    }
    .nonotDrag .van-slider__button {
      // display: none;
      width: 1px;
      height: 1px;
      background: rgba(255, 255, 255, 0.4);
    }
  }

  .setVideo {
    height: 100%;
    overflow: hidden;
  }

  .iconfont.icon-bofang.suspend {
    font-size: 56px;
    color: rgb(255, 255, 255);
    opacity: 0.6;
    z-index: 999;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    animation: playButton 0.1s linear 0s;
  }

  //播放按钮动画
  @keyframes playButton {
    // 开始
    0% {
      font-size: 80px;
      opacity: 0;
    }
    //结束
    100% {
    }
  }

  .video-js .vjs-big-play-centered {
    position: relative;
  }
}
</style>
