<template>
  <!-- 标签页 -->
  <div class="HomePage">
    <div class="base_head">
      <div>
        <img
          src="http://m.mrjianwei.top/images/icon/richang_03.jpg"
          alt=""
        />
      </div>
      <div>
        <van-tabs v-model="active">
          <van-tab title="关注"></van-tab>
          <van-tab title="发现"></van-tab>
          <van-tab title="广州"></van-tab>
        </van-tabs>
      </div>
      <div>
        <span class="iconfont icon-sousuo1"></span>
      </div>
    </div>

    <div class="home_content">
      <!-- 关注 -->
      <div class="attention" v-show="active == 0">
        <AttentionPageVue></AttentionPageVue>
      </div>

      <!-- 发现 -->
      <div class="discover" v-show="active == 1">
        <discover :active="active"></discover>
      </div>

      <!-- 地区 -->
      <div class="area" v-show="active == 2">
        <AreaVue></AreaVue>
      </div>
    </div>
  </div>
</template>

<script>
import discover from "@/components/homepage/Discover.vue";
import AttentionPageVue from './AttentionPage.vue';
import AreaVue from "@/components/homepage/Area.vue"
export default {
  components: {
    discover,
    AttentionPageVue,
    AreaVue
  },

  data() {
    return {
      active: 1,
    };
  },

  created() {},
  beforeMount() {},
  mounted() {},
  updated() {},

  methods: {
    text() {
      // 跳转视频播放页
      this.$router.push({
        path: "/videoPage",
        query: {
          index: 1,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.HomePage {
  padding-top: 48px;
}
.home_content {
}

.discover,
.area {
  background-color: #fafafa;
}
.base_head {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  right: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  height: 47px;
  border-bottom: 1px solid #e8e8e8;

  // 修改van-tabs样式
  /deep/.van-tabs__line {
    width: 30px;
  }

  /deep/.van-tabs--line .van-tabs__wrap {
    height: 32px;
  }

  & > div {
    &:nth-child(1) {
      width: 26px;
      height: 26px;
      margin-left: 20px;

      img {
        width: 100%;
        height: 100%;
      }
    }
    &:nth-child(2) {
      width: 200px;
      margin: 0 auto;
    }
    &:nth-child(3) {
      margin-right: 20px;
      .icon-sousuo1 {
        font-size: 24px;
        color: #303030;
      }
    }
  }
}
</style>
