<template>
  <div class="VideoPage">
    <div class="backButton">
      <span class="iconfont icon-xiangzuo" @click="backUp"></span>
    </div>

    <PlayerVideo class="player_video" :srcUrl="videoUrl"></PlayerVideo>

    <!--  视频描述 -->
    <div class="video-describe" v-show="!isShowDescribe">
      <div class="user">
        <img
          class="user-photo"
          :src="videoObj.touXiang"
          alt="用户头像"
          srcset=""
        />
        <div class="user-name">{{ videoObj.userName }}</div>

        <div
          :class="
            isAttention
              ? 'user-attention select-off'
              : 'user-attention select-on'
          "
          @click="selectOnOff()"
        >
          <span v-if="!isAttention">关注</span>
          <span v-if="isAttention">已关注</span>
        </div>
      </div>
      <div class="describe-text">
        <div class="text">
          {{ videoObj.title }}
        </div>
        <div class="btn"></div>
      </div>
    </div>
    <div class="open" @click.stop="describeOffAndOn()">
      <div class="zhankai-bg">
        <!-- <span class="iconfont icon-zhankai zhankai"></span> -->
        <span
          :class="
            isShowDescribe
              ? 'iconfont icon-suoxiao zhankai'
              : 'iconfont icon-zhankai zhankai'
          "
        ></span>
      </div>
    </div>

    <div class="comment">
      <div class="publish">
        <span class="iconfont icon-danping"></span>
        <span></span>
        <span>发弹幕</span>
      </div>
      <div class="comment_button">
        <div @click="clickDianzan()">
          <van-icon name="like-o" v-if="!videoObj.dianzanState" />
          <van-icon name="like" color="#ff3647" v-if="videoObj.dianzanState" />
          <span>{{ videoObj.dianzan }}</span>
        </div>
        <div @click="clickShouchang()">
          <van-icon name="star-o" v-if="!videoObj.shouchangState" />
          <van-icon
            name="star"
            color="#fabd60"
            v-if="videoObj.shouchangState"
          />
          <span>{{ videoObj.shouchang }}</span>
        </div>
        <div @click="showCommentPopup()">
          <van-icon name="chat-o" />
          <span>{{ videoObj.pinlin }}</span>
        </div>
      </div>
    </div>

    <!-- 收藏提示  -->
    <div v-if="show" class="shoucheng_toast">
      <van-icon name="checked" />
      <span>收藏成功</span>
    </div>

    <!-- 评论 -->
    <van-popup
      v-model="commentShow"
      round
      position="bottom"
      :style="{ height: '60%' }"
      class="div_van_popup"
      v-if="commentShow"
    >
      <div class="title" @click="closeCommentPopup()">
        <span>共 {{ videoObj.pinlin }}有条评论</span>
        <van-icon name="cross" />
      </div>
      <div class="comment_content">
        <div
          class="comment_item"
          v-for="(item, index) in videoObj.pinlunArray"
          :key="index"
        >
          <div class="comment_user_img">
            <img :src="item.touxiang" alt="" />
          </div>
          <div class="comment_text">
            <div class="comment_user_name">
              {{ item.username }}
            </div>
            <div class="comment_user_text">
              {{ item.pinlunCon }}
            </div>
            <div class="div_van-icon" @click="commentIsLike(index)">
              <van-icon
                class="comment_false"
                v-if="item.dianzan == 0"
                name="like-o"
              />
              <van-icon class="comment_true" v-else name="like" />
            </div>
          </div>
        </div>
      </div>

      <div class="comment_publish">
        <div>
          <span>喜欢就给个评论支持一下~</span>
          <div class="publish_icon">
            <span>@</span>
            <van-icon name="smile-o" />
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getHomeMsgList } from "../api/homeApi";
import PlayerVideo from "@/components/PlayerVideo.vue";
import { debounce } from "@/utils/util";
export default {
  components: {
    PlayerVideo,
  },
  data() {
    return {
      commentShow: false,
      show: false,
      videoObj: {},
      videoUrl: "",
      isAttention: false, //是否关注
      AttentionItem: null,
      isShowDescribe: false, //是否隐藏描述
    };
  },
  created() {
    this.getData();
  },
  beforeMount() {},
  mounted() {},
  methods: {
    //是否关闭描述
    describeOffAndOn() {
      this.isShowDescribe = this.isShowDescribe ? false : true;
    },
    // 是否已经关注
    selectOnOff() {
      if (this.isAttention) {
        this.isAttention = false;
      } else {
        this.isAttention = true;
      }

      if (this.AttentionItem !== null) {
        clearTimeout(this.AttentionItem);
        this.AttentionItem = null;
      }
      this.AttentionItem = setTimeout(() => {
        // 去请求更改是否关注
        clearTimeout(this.AttentionItem);
        this.AttentionItem = null;
      }, 2000);
    },
    showCommentPopup() {
      //显示留言
      this.commentShow = true;
    },
    closeCommentPopup() {
      //关闭留言
      this.commentShow = false;
    },
    commentIsLike: debounce(function (index) {
      // 留言点击爱心
      if (this.videoObj.pinlunArray[index].dianzan == 0) {
        this.videoObj.pinlunArray[index].dianzan = 1;
      } else {
        this.videoObj.pinlunArray[index].dianzan = 0;
      }
    }),
    clickShouchang: debounce(function () {
      // 收藏
      if (this.videoObj.shouchangState) {
        this.videoObj.shouchang = --this.videoObj.shouchang;
      } else {
        this.videoObj.shouchang = ++this.videoObj.shouchang;
        this.show = true;
        let hist = this;
        let setOut = setTimeout(function () {
          hist.show = false;
          clearTimeout(setOut);
        }, 2000);
      }
      this.videoObj.shouchangState = !this.videoObj.shouchangState;
    }),
    clickDianzan: debounce(function () {
      //点赞
      if (this.videoObj.dianzanState) {
        this.videoObj.dianzan = --this.videoObj.dianzan;
      } else {
        this.videoObj.dianzan = ++this.videoObj.dianzan;
      }
      this.videoObj.dianzanState = !this.videoObj.dianzanState;
    }, 300),
    getData() {
      this.isAttention = this.$route.params.attention;
      this.videoObj = this.$route.params;
      this.videoUrl = this.$route.params.videoSrc.src;
    },
    backUp() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.VideoPage {
  widows: 100%;
  height: 100%;
}

.player_video {
  width: 100%;
  height: 100%;
  padding-bottom: 44px;
}
.backButton {
  z-index: 999;
  position: fixed;
  padding: 13px 16px;
  width: 100%;
  box-sizing: border-box;

  .iconfont {
    color: #fff;
    font-size: 24px;
  }
}

.video-describe {
  position: fixed;
  bottom: 70px;
  z-index: 999;

  color: #fff;
  width: 82%;
  padding-left: 24px;
  box-sizing: border-box;
  .user {
    display: flex;
    align-items: center;

    .user-photo {
      width: 34px;
      height: 34px;
      border-radius: 20px;
    }

    .user-name {
      font-size: 14px;
      padding: 0 12px;
    }

    .user-attention {
      text-align: center;
      width: 63px;
      height: 20px;
      letter-spacing: 2px;
      border-radius: 15px;
      line-height: 20px;
      span {
        display: block;
        transform: scale(0.7);
        font-size: 12px;
      }
    }
    // 未关注
    .select-on {
      background-color: #fb3847;
    }
    //已关注
    .select-off {
      background: transparent;
      border: 1px solid #fff;
    }
  }
  .describe-text {
    margin-top: 15px;
    position: relative;
    /* 1.多行溢出隐藏 */
    overflow: hidden;

    .text {
      /* 2.用省略号来代替超出文本 */
      text-overflow: ellipsis;
      /* 3.设置盒子属性为-webkit-box  必须的 */
      display: -webkit-box;
      /* 4.-webkit-line-clamp 设置为2，表示超出2行的部分显示省略号，如果设置为3，那么就是超出3行部分显示省略号 */
      -webkit-line-clamp: 2;
      /* 5.字面意思：单词破坏：破坏英文单词的整体性，在英文单词还没有在一行完全展示时就换行  即一个单词可能会被分成两行展示 */
      word-break: break-all;
      /* 6.盒子实现多行显示的必要条件，文字是垂直展示，即文字是多行展示的情况下使用 */
      -webkit-box-orient: vertical;

      font-size: 14px;
      letter-spacing: 1px;
      line-height: 22px;
    }
    .btn {
    }

    // height: 44px;
  }
}
.open {
  z-index: 998;
  position: fixed;
  right: 20px;
  bottom: 70px;

  .zhankai-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgba(75, 75, 75, 0.7);
    .iconfont.icon-zhankai {
      transform: rotate(45deg);
    }
    .zhankai {
      font-size: 16px;
      color: rgb(255, 255, 255);
    }
  }
}

.comment {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 46px;
  padding: 5px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: #303030;
  z-index: 999;

  .publish {
    width: 165px;
    height: 26px;
    border-radius: 26px;
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    color: #eeeeee;

    & > :nth-child(1) {
      font-size: 16px;
      vertical-align: middle;
      margin-left: 18px;
    }
    & > :nth-child(2) {
      width: 1px;
      display: inline-block;
      height: 14px;
      background-color: #eeeeee;
      margin-left: 8px;
    }
    & > :nth-child(3) {
      font-size: 12px;
      margin-left: 8px;
    }
  }

  .comment_button {
    display: flex;

    .van-icon {
      font-size: 22px;
      color: #fff;
    }

    & > div {
      margin-left: 12px;
      display: flex;
      align-items: center;

      & > span {
        &:last-child {
          color: #fff;
          font-size: 12px;
          margin-left: 5px;
        }
      }
    }
  }
}

.shoucheng_toast {
  background-color: #333;
  padding: 12px 20px;
  border-radius: 10px;
  width: 80%;
  box-sizing: border-box;
  color: #fff;
  z-index: 999;

  animation: shouChengToast 2s linear 0s;
  opacity: 0;

  position: fixed;
  left: 50%;
  bottom: 76px;
  transform: translateX(-50%);

  .van-icon {
    font-size: 24px;
  }
  span {
    margin-left: 10px;
    font-size: 16px;
    vertical-align: middle;
  }
}

@keyframes shouChengToast {
  10% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* 设置持续时间和动画函数 */
// .v-enter-active,
// .v-leave-active {
//   transition: all 2.5s ease;
// }
// .v-enter {
//   opacity: 1;
// }
// .v-leave-to {
//   opacity: 0;
// }

// 评论
.div_van_popup {
  overflow: hidden;
}
.title {
  text-align: center;
  height: 49px;
  line-height: 49px;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #e8e8e8;
  span {
    color: #303030;
    font-size: 16px;
    font-weight: 600;
  }
  .van-icon {
    font-size: 22px;
    position: absolute;
    right: 16px;
    top: 17px;
  }
}
.comment_content {
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 110px;
  box-sizing: border-box;
}
.comment_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;

  .comment_user_img {
    width: 35px;
    height: 35px;
    overflow: hidden;
    border-radius: 30px;
    margin-left: 15px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .comment_text {
    position: relative;
    width: 84%;

    .div_van-icon {
      font-size: 18px;
      position: absolute;
      top: 0;
      right: 13px;
    }

    .comment_false {
      color: #555;
    }
    .comment_true {
      color: #fd3847;
    }
  }
  .comment_user_name {
    color: #9b9b9b;
    font-size: 13px;
  }
  .comment_user_text {
    font-size: 13px;
    color: #303030;
    margin-top: 8px;
    line-height: 19px;
    border-bottom: 0.02667rem solid #e8e8e8;
    padding-bottom: 9px;
    padding-right: 21px;
  }
}

.comment_publish {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 42px;
  padding: 0 10px;
  box-shadow: 9px 0px 3px 0px #f8f8f8;
  display: flex;
  align-items: center;
  background-color: #fff;
  & > div {
    width: 100%;
    height: 30px;
    background-color: #f6f6f6;
    font-size: 18px;
    color: #696969;
    border-radius: 15px;
    line-height: 30px;
    padding-left: 16px;
    display: flex;
    justify-content: space-between;

    & > span {
      font-size: 12px;
      letter-spacing: 1px;
    }

    .publish_icon {
      span {
        margin-right: 10px;
      }

      .van-icon {
        margin-right: 15px;
        vertical-align: middle;
      }
    }
  }
}
</style>
