import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./vantUI/ui";



// import '@/mock/index';
import("@/assets/css/reset.css");

// 瀑布流插件
import { VueMasonryPlugin } from "vue-masonry";

// 阿里巴巴矢量图
import "@/assets/icon/iconfont.css";

import videojs from "video.js";
import "video.js/dist/video-js.css";
Vue.prototype.$video = videojs;

// 移动端控制台
import Vconsole from 'vconsole'
const vConsole = new Vconsole()



// 适配
import "amfe-flexible";
import "amfe-flexible/index.js";

Vue.use(VueMasonryPlugin);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
