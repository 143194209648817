var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"messagePage"},[_vm._m(0),_c('div',{staticClass:"classification"},[_c('div',{on:{"click":function($event){return _vm.beContinued()}}},[_vm._m(1),_c('span',[_vm._v("赞和收藏")])]),_c('div',{on:{"click":function($event){return _vm.beContinued()}}},[_vm._m(2),_c('span',[_vm._v("新增关注")])]),_c('div',{on:{"click":function($event){return _vm.beContinued()}}},[_vm._m(3),_c('span',[_vm._v("评论和@")])])]),_c('div',{staticClass:"messagePage_item"},[_c('div',{on:{"click":function($event){return _vm.beContinued()}}},[_vm._m(4),_vm._m(5)]),_c('div',{on:{"click":function($event){return _vm.beContinued()}}},[_vm._m(6),_vm._m(7)]),_c('div',{on:{"click":function($event){return _vm.beContinued()}}},[_vm._m(8),_vm._m(9)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"text_center"},[_vm._v("消息")]),_c('div',{staticClass:"text_left"},[_c('span',{staticClass:"iconfont icon-renqun"}),_c('span',[_vm._v("发现聊群")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iconfont_base"},[_c('span',{staticClass:"iconfont icon-aixin_shixin"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iconfont_base"},[_c('span',{staticClass:"iconfont icon-renyuan"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iconfont_base"},[_c('span',{staticClass:"iconfont icon-bg-chat"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iconfont_item_bottom"},[_c('span',{staticClass:"iconfont icon-bg-chat"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title_text"},[_c('span',[_vm._v("信息通知")]),_c('span',[_vm._v("恭喜你,成功在小红书发布第一篇笔记!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iconfont_item_bottom"},[_c('span',{staticClass:"iconfont icon-gupiao"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title_text"},[_c('span',[_vm._v("作者助手")]),_c('span',[_vm._v("有事就问小红书")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iconfont_item_bottom"},[_c('span',{staticClass:"iconfont icon-xinxi"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title_text"},[_c('span',[_vm._v("陌生人消息")]),_c('span',[_vm._v("小红书创造助手:照片神马的当然要分享啦!小红书创造助手:照片神马的当然要分享啦!")])])
}]

export { render, staticRenderFns }