import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/home/HomeView.vue";
import AddPage from "../views/AddPage.vue";
import ErrorView from "../views/ErrorView.vue";
import NotFound from "../views/NotFoundView.vue";
import VideoPage from "../views/VideoPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "仿小红书",
      keepAlive:true
    },
  },
  {
    path: "/addPage",
    name: "addPage",
    component: AddPage,
    meta: {
      title: "发布",
    },
  },
  {
    path: "/errorView",
    name: "errorView",
    component: ErrorView,
  },
  {
    path: "/notFound",
    name: "notFound",
    component: NotFound,
  },
  {
    path: "/videoPage",
    name: "videoPage",
    component: VideoPage,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
