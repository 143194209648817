<template>
    <div class="page">
        <van-empty class="back_empty" image="error" description="出错了" />
        <van-button @click="blockHome()" class="back_button" type="primary">返回首页</van-button>
    </div>
</template>

<script>
export default {
    methods: {
        blockHome: function () {
            this.$router.replace({
                path: "/"
            })
        }
    }
}
</script>

<style>
.back_empty {
    margin-top: 20%;
}

.back_button {
    position: fixed !important;
    left: 50%;
    bottom: 20%;
    transform: translateX(-50%);
    width: 80%;
}
</style>