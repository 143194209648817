<template>
  <div class="storePage"><van-empty image="error" description="开发中" /></div>
</template>

<script>
export default {

}
</script>

<style>

</style>