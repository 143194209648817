<template>
  <div class="mePage">
    <!-- 背景图 -->
    <img class="bg" :src="userMessage.userBg" alt="背景图" />
    <div class="nav_div" :style="bgOpacityStyle">
      <div>
        <span class="iconfont icon-caidan"></span>
      </div>
      <div>
        <span class="iconfont icon-saoma"></span>
        <span class="iconfont icon-zhuanfa"></span>
      </div>
    </div>
    <div class="user">
      <div class="user_message">
        <div class="head_portrait_or_name">
          <div class="user_head_portrait">
            <img :src="userMessage.userTx" alt="这是头像" srcset="" />
            <div>
              <span class="iconfont icon-jia"></span>
            </div>
          </div>
          <div class="user_message_text">
            <div class="user_name">{{ userMessage.userName }}</div>
            <div class="user_text mtop">
              <span>小红书号：123456789</span>
              <span class="iconfont icon-erweima"></span>
            </div>
            <div class="user_text">
              <span class="a">IP属地：广东</span>
              <span class="iconfont icon-zhuyi"></span>
            </div>
          </div>
        </div>
        <div class="leave_a_message">点击这里， 填写简介</div>
        <div class="gender">
          <span class="iconfont icon-nan"></span>
        </div>
      </div>
      <div class="flex_between_center margin_15 margin_top_18px">
        <div class="flex font_fff text_center">
          <div class="margin_right_15">
            <div>0</div>
            <div class="margin_top_6">关注</div>
          </div>
          <div class="margin_right_15">
            <div>0</div>
            <div class="margin_top_6">粉丝</div>
          </div>
          <div class="margin_right_15">
            <div>1</div>
            <div class="margin_top_6">获赞和收藏</div>
          </div>
        </div>
        <div class="flex setting_up">
          <span class="font_fff margin_right_15">编辑资料</span>
          <span class="iconfont icon-shezhi1 font_fff"></span>
        </div>
      </div>
      <div class="flex_between_center font_fff user_liebiao margin_15">
        <div>
          <div class="iconfont_span_text">
            <span class="iconfont icon-gouwuche"></span>
            <span>购物车</span>
          </div>
          <span>3个商品</span>
        </div>
        <div>
          <div class="iconfont_span_text">
            <span class="iconfont icon-linggan"></span>
            <span>创作灵感</span>
          </div>
          <span>学创作找灵感</span>
        </div>
        <div>
          <div class="iconfont_span_text">
            <span class="iconfont icon-renqun"></span>
            <span>群聊</span>
          </div>
          <span>查看群聊</span>
        </div>
      </div>
    </div>
    <div class="user_content" :style="userContentHeight">
      <van-tabs v-model="activeName" class="user_content_van_tabs">
        <van-tab title="笔记"></van-tab>
        <van-tab title="收藏"></van-tab>
        <van-tab title="赞过"></van-tab>
      </van-tabs>
      <div class="xian user_content_con">
        <div v-show="activeName == 0">
          <div class="note_title">
            <div
              @click="activeNote = 0"
              :class="activeNote == 0 ? 'note_title_color' : ''"
            >
              公开 · 0
            </div>
            <div
              @click="activeNote = 1"
              :class="activeNote == 1 ? 'note_title_color' : ''"
            >
              <span class="iconfont icon-jiesuo"></span>
              <span>私密 · 0</span>
            </div>
            <div
              @click="activeNote = 2"
              :class="activeNote == 2 ? 'note_title_color' : ''"
            >
              合集
            </div>
          </div>
          <div class="note_content xian">
            <div v-show="activeNote == 0">
              <van-empty
                class="van_empty_d"
                description="推荐一下你家的神仙好物"
              />
            </div>
            <div v-show="activeNote == 1">
              <van-empty class="van_empty_d" description="没有任何私密笔记" />
            </div>
            <div v-show="activeNote == 2">
              <van-empty class="van_empty_d" description="创作你的第一个合集" />
            </div>
          </div>
        </div>
        <div v-show="activeName == 1">
          <div class="collect_title note_title">
            <div
              @click="activeCollect = 0"
              :class="activeCollect == 0 ? 'note_title_color' : ''"
            >
              笔记 · 3
            </div>
            <div
              @click="activeCollect = 1"
              :class="activeCollect == 1 ? 'note_title_color' : ''"
            >
              <span>专辑 · 0</span>
            </div>
          </div>
          <div class="collect_content xian">
            <div v-show="activeCollect == 0">
              <van-empty
                class="van_empty_d"
                description="你没有收藏任何笔记哦"
              />
            </div>
            <div v-show="activeCollect == 1">
              <van-empty
                class="van_empty_d"
                description="你还没有创建任何专辑哦"
              />
            </div>
          </div>
        </div>
        <div v-show="activeName == 2">
          <div class="note_content">
            <van-empty image="network" description="没有任何点赞哦" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUser } from "@/api/homeApi";
export default {
  data() {
    return {
      activeCollect: 0,
      activeNote: 0,
      activeName: 1,
      userMessage: {},
      bgOpacity: 1,
      bgOpacityStyle: {
        background: "rgba(33, 33, 33, 0)",
      },
      userContentHeight: {
        height: 0,
      },
      h: 10,
    };
  },
  created() {},
  mounted() {
    this.getUserMessage();
    this.setuserContentHeight();
  },
  methods: {
    setuserContentHeight() {
      // 设置高度
      let h = document.documentElement.clientHeight;
      let height = parseInt(h) - 31 - 50;
      this.userContentHeight.height = height + "px";
    },
    getUserMessage() {
      // 获取用户信息
      getUser().then((res) => {
        this.userMessage = res;
      });
    },
  },
};
</script>

<style lang="less" scoped>
html,
body {
  height: auto;
}
.flex_center_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex_between_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex_evenly_center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.flex {
  display: flex;
}
.font_fff {
  font-size: 12px;
  color: #d8d8d8;
}
.margin_15 {
  margin: 0 15px;
}
.margin_right_15 {
  margin-right: 15px;
}
.text_center {
  text-align: center;
}
.margin_top_6 {
  margin-top: 6px;
}
.margin_top_18px {
  margin-top: 18px;
}
.mePage {
  position: relative;
  //   width: 100%;
  // height: 330px;
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 330px;
}

.nav_div {
  padding: 3px 15px 3px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 25px;
  z-index: 999;

  span {
    vertical-align: middle;
  }

  .iconfont {
    color: #fff;
    font-size: 20px;
    opacity: 0.8;
  }

  .icon-saoma {
    margin-right: 16px;
    font-size: 17px;
  }

  .icon-zhuanfa {
    font-size: 22px;
  }
}

.user {
  .user_message {
    margin: 16px 15px 0 15px;

    .head_portrait_or_name {
      display: flex;

      .user_head_portrait {
        position: relative;

        img {
          width: 65px;
          height: 65px;
          border-radius: 35px;
          overflow: hidden;
        }

        & > div {
          position: absolute;
          right: 2px;
          bottom: 2px;
          width: 18px;
          height: 18px;
          border-radius: 10px;
          background-color: #fae93e;

          display: flex;
          justify-content: center;
          align-items: center;
        }
        .icon-jia {
          font-size: 8px;
          color: #383838;
        }
      }

      .user_message_text {
        margin-left: 25px;

        .user_name {
          color: #fff;
          opacity: 0.8;
          font-size: 16px;
          letter-spacing: 2px;
        }

        .user_text {
          font-size: 9px;
          color: #fff;
          opacity: 0.5;
          margin-top: 4px;
          letter-spacing: 1px;

          .a {
            vertical-align: text-top;
          }
        }

        .mtop {
          margin-top: 12px;
        }

        .iconfont {
          font-size: 10px;
          margin-left: 5px;
        }
      }
    }

    .leave_a_message {
      color: #fff;
      font-size: 12px;
      opacity: 0.8;
      margin-top: 18px;
    }

    .gender {
      margin-top: 10px;
      width: 36px;
      height: 20px;
      background: rgba(225, 225, 225, 0.4);
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .icon-nan {
        color: #88c4ef;
        font-size: 8px;
      }
    }
  }

  .setting_up {
    font-weight: 800;
    span {
      padding: 6px 14px;
      border-radius: 15px;
      border: 1px solid #d8d8d8;
    }
  }
  .user_liebiao {
    margin-top: 20px;

    .font_10 {
      font-size: 10px;
    }

    & > div {
      width: 110px;
      height: 45px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 6px;
      padding: 8px 10px;
      box-sizing: border-box;

      & > span {
        font-size: 10px;
        margin-top: 6px;
        display: inline-block;
      }

      .iconfont_span_text {
        span {
          vertical-align: middle;
        }
        span:nth-child(1) {
          font-size: 14px;
          margin-right: 5px;
          color: #fff;
        }
        span:nth-child(2) {
          font-size: 12px;
          margin-right: 5px;
          color: #fff;
        }
      }
    }
  }
}

.user_content {
  width: 100%;
  border-radius: 15px 15px 0 0;
  background-color: #fff;
  margin-top: 12px;
}
.user_content_van_tabs {
  padding: 0 20%;
  box-sizing: border-box;
}

.xian {
  border-top: 1px solid #e8e8e8;
}
.user_content_con {
  .note_title_color {
    color: #333;
  }
  .note_title {
    font-size: 12px;
    font-weight: 600;
    color: #9a9a9a;
    display: flex;
    align-items: center;
    margin: 14px 20px;

    & > div {
      margin-right: 28px;
    }

    .icon-jiesuo {
      margin-right: 2px;
      font-size: 11px;
      font-weight: 1000;
    }
  }
  .collect_content,
  .note_content {
    padding: 10px;
    /deep/.van-empty__image {
      width: 40% !important;
    }
  }
}
</style>
