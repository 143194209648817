<template>
  <div class="attention_page">
    <!-- 关注页面 -->
    <div class="share">
      <div class="share_button">
        <img :src="user.userTx" alt="" srcset="" />
        <div class="share_jia">
          <span class="iconfont icon-jia"></span>
        </div>
      </div>
      <span>分享瞬间</span>
    </div>
    <div class="text">
      <div>还没有关注的人呢</div>
      <div>关注后,可以在这里查看对方的最新动态</div>
    </div>

    <div class="referrer">
      <div class="referrer_content">
        <!-- "++'px -->
        <div
          class="referrer_content_ke"
          :style="{ width: sharePerson.length * 190 + 'px' }"
        >
          <div
            class="referrer_item"
            v-for="(item, index) in sharePerson"
            :key="index"
          >
            <span
              class="iconfont icon-quxiao"
              @click="cancelSharePerson(index)"
            ></span>
            <div class="referrer_tx">
              <img class="" :src="item.userTx" alt="" srcset="" />
            </div>
            <div class="referrer_text">
              <div>{{ item.name }}</div>
              <div>{{ item.date }}发了笔记</div>
            </div>
            <div class="referrer_img">
              <img v-for="(i, j) in item.imgList" :key="j" :src="i" alt="" />
            </div>
            <div class="referrer_buttom">
              <span
                class="referrer_buttom_off"
                @click="attentionOff(index)"
                v-if="item.attention == 0"
                >关注</span
              >
              <span
                class="referrer_buttom_on"
                @click="attentionOn(index)"
                v-else
                >已关注</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 取消关注提示框 -->
    <van-popup class="attentio_state" v-model="attentionState">
      <div class="attentio_state_text">确定不在关注</div>
      <dir class="attentio_state_bottom">
        <div @click="attentionState = false">取消</div>
        <div @click="cancelAttention()">确定</div>
      </dir>
    </van-popup>
  </div>
</template>

<script>
import { getUser, getHomeAttentionList } from "@/api/homeApi";
import { Toast } from 'vant';
export default {
  data() {
    return {
      user: {},
      sharePerson: [],
      attentionState: false,
      sharePersonIndex: 0,
      index: 0,
    };
  },

  beforeCreate() {},
  created() {
    this.getUserData();
    this.getAttentionList();
  },
  mounted() {},
  methods: {
    cancelSharePerson(index) {
      //取消关注的人
      this.sharePerson.splice(index,1);
      Toast('不再为您推荐该用户');
    },
    attentionOn(i) {
      this.index = i;
      this.attentionState = true;
    },
    cancelAttention() {
      this.sharePerson[this.index].attention = 0;
      this.attentionState = false;

      if (this.sharePerson.length != this.index) {
        this.$refs.Swipe.next();
      }
    },
    attentionOff(index) {
      let el = this.sharePerson[index];
      this.sharePersonIndex = index;

      if (el.attention == 0) {
        this.sharePerson[index].attention = 1;
      } else {
        this.sharePerson[index].attention = 0;
      }

      if (this.sharePerson.length != index) {
        this.$refs.Swipe.next();
      }
    },
    getUserData() {
      getUser().then((res) => {
        this.user = res;
      });
    },
    getAttentionList() {
      getHomeAttentionList().then((res) => {
        this.sharePerson = res;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.attention_page {
  .share {
    width: 100px;
    height: 100px;
    text-align: center;
    padding-top: 15px;
    background-color: #fff;

    .share_button {
      position: relative;

      .share_jia {
        position: absolute;
        right: 20px;
        bottom: 0px;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        background-color: #f5e93e;
        display: flex;
        justify-content: center;
        align-items: center;

        .iconfont {
          font-size: 10px;
        }
      }
    }

    img {
      width: 60px;
      height: 60px;
      border-radius: 30px;
      border: 2px solid #e9e9e9;
    }

    & > span {
      display: block;
      font-size: 12px;
      text-align: center;
      letter-spacing: 1px;
      margin-top: 12px;
    }
  }

  .text {
    margin-top: 40px;
    text-align: center;
    div {
      letter-spacing: 1px;

      &:first-child {
        font-size: 14px;
        color: #2c2c2c;
        font-weight: 600;
      }
      &:last-child {
        font-size: 11px;
        color: #989898;
        margin-top: 12px;
      }
    }
  }

  .referrer {
    width: 100%;
    height: 424px;
    background-color: #fafafa;
    margin-top: 22px;

    // 隐藏滚动条
    .referrer_content::-webkit-scrollbar {
      display: none;
    }
    .referrer_content {
      padding-top: 40px;
      padding-left: 100px;
      overflow: scroll;

      .referrer_content_ke {
        display: flex;
        // width: 1000%;
      }

      .van-swipe {
        // padding-top: 32px;
        padding-left: 100px;
        width: 275px;
      }

      .van-swipe-item {
        // width: 187px !important;
      }

      .van-swipe__track {
        flex-shrink: 0;
      }

      .referrer_item {
        margin-left: 10px;
        width: 180px;
        height: 280px;
        border-radius: 10px;
        background-color: #fff;
        padding: 0 4px;
        box-sizing: border-box;
        // margin-right: 8px;

        .icon-quxiao {
          color: #d3d3d3;
          margin-top: 14px;
          margin-right: 15px;
          font-size: 15px;
          float: right;
        }

        .referrer_tx {
          text-align: center;
          padding-top: 31px;

          img {
            border-radius: 30px;
            width: 60px;
            height: 60px;
          }
        }

        .referrer_text {
          text-align: center;
          margin-top: 15px;
          & > div {
            letter-spacing: 1px;
            &:first-child {
              color: #383838;
              font-size: 11px;
              font-weight: 600;
            }
            &:last-child {
              color: #979797;
              font-size: 7px;
              margin-top: 8px;
            }
          }
        }

        .referrer_img {
          margin-top: 10px;
          display: flex;

          & > img {
            display: inline-block;
            border-radius: 5px;
            width: 33.33%;
            height: auto;
            box-sizing: border-box;
          }
          img:nth-child(2) {
            padding: 0 3px;
          }
        }

        .referrer_buttom {
          text-align: center;
          line-height: 14px;
          margin-top: 24px;
          span {
            font-size: 12px;
            letter-spacing: 2px;
            width: 76px;
            height: 22px;
            line-height: 22px;
            border-radius: 12px;
            display: inline-block;
          }

          .referrer_buttom_off {
            color: #fff;
            background-color: #fd3847;
          }
          .referrer_buttom_on {
            border: 1px solid #cccccc;
          }
        }
      }
    }
  }

  .attentio_state {
    border-radius: 5px;
    font-size: 18px;
    width: 228px;
    // height: 95px;

    .attentio_state_text {
      text-align: center;
      padding: 22px 0;
      font-size: 14px;
      color: #363636;
      font-weight: 600;
      letter-spacing: 1px;
    }
    .attentio_state_bottom {
      border-top: 2px solid #e6e6e6;
      display: flex;
      justify-content: space-between;

      & > div {
        width: 50%;
        height: 42px;
        color: #6794de;
        text-align: center;
        line-height: 42px;
        font-size: 14px;
        letter-spacing: 2px;

        &:first-child {
          border-right: 2px solid #e6e6e6;
        }
      }
    }
  }
}
</style>
>
