import Vue from "vue";

import {
  Button,
  Tabbar,
  TabbarItem,
  Icon,
  Empty,
  Toast,
  NavBar,
  Progress,
  Popup,
  List,
  Cell,
  Tab,
  Tabs,
  Slider,
  Swipe,
  SwipeItem
} from "vant";
Vue.use(Button)
  .use(SwipeItem)
  .use(Swipe)
  .use(Tabbar)
  .use(TabbarItem)
  .use(Icon)
  .use(Empty)
  .use(Toast)
  .use(NavBar)
  .use(Popup)
  .use(List)
  .use(Cell)
  .use(Tab)
  .use(Tabs)
  .use(Progress)
  .use(Slider);
