<template>
  <div class="messagePage">
    <div class="title">
      <div class="text_center">消息</div>
      <div class="text_left">
        <span class="iconfont icon-renqun"></span>
        <span>发现聊群</span>
      </div>
    </div>
    <div class="classification">
      <div @click="beContinued()">
        <div class="iconfont_base">
          <span class="iconfont icon-aixin_shixin"></span>
        </div>
        <span>赞和收藏</span>
      </div>
      <div @click="beContinued()">
        <div class="iconfont_base">
          <span class="iconfont icon-renyuan"></span>
        </div>
        <span>新增关注</span>
      </div>
      <div @click="beContinued()">
        <div class="iconfont_base">
          <span class="iconfont icon-bg-chat"></span>
        </div>
        <span>评论和@</span>
      </div>
    </div>
    <div class="messagePage_item">
      <div @click="beContinued()">
        <div class="iconfont_item_bottom">
          <span class="iconfont icon-bg-chat"></span>
        </div>
        <div class="title_text">
          <span>信息通知</span>
          <span>恭喜你,成功在小红书发布第一篇笔记!</span>
        </div>
      </div>
      <div @click="beContinued()">
        <div class="iconfont_item_bottom">
          <span class="iconfont icon-gupiao"></span>
        </div>
        <div class="title_text">
          <span>作者助手</span>
          <span>有事就问小红书</span>
        </div>
      </div>
      <div @click="beContinued()">
        <div class="iconfont_item_bottom">
          <span class="iconfont icon-xinxi"></span>
        </div>
        <div class="title_text">
          <span>陌生人消息</span>
          <span
            >小红书创造助手:照片神马的当然要分享啦!小红书创造助手:照片神马的当然要分享啦!</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  methods:{
    beContinued(){
      Toast('功能正在开发中。。');
    }
  }
};
</script>

<style lang="less" scoped>
.title {
  margin-top: 15px;
  position: relative;
  .text_center {
    font-size: 15px;
    text-align: center;
  }

  .text_left {
    font-size: 13px;
    position: absolute;
    right: 10px;
    top: 0px;

    span {
      font-size: 13px;
    }

    .icon-renqun {
      margin-right: 5px;
    }
  }
}

.classification {
  margin-top: 30px;

  display: flex;
  justify-content: space-evenly;

  & > div {
    text-align: center;
    &:nth-child(1) {
      .iconfont_base {
        background-color: #fdefea;
      }
      .iconfont {
        color: #f9635d;
      }
    }
    &:nth-child(2) {
      .iconfont_base {
        background-color: #e7f3fe;
      }
      .iconfont {
        color: #4888fe;
      }
    }
    &:nth-child(3) {
      .iconfont_base {
        background-color: #e9fcf3;
      }
      .iconfont {
        color: #5edc98;
      }
    }

    .iconfont_base {
      width: 50px;
      height: 50px;
      border-radius: 15px;
      display: table;

      .iconfont {
        display: table-cell;
        vertical-align: middle;
        font-size: 24px;
      }
    }

    span {
      font-size: 13px;
      font-weight: 400;

      &:nth-child(2) {
        display: block;
        margin-top: 15px;
      }
    }
  }
}

.messagePage_item {
  margin-top: 20px;
  padding: 0 10px;

  & > div {
    display: flex;
    padding-top: 30px;

    .iconfont_item_bottom {
      width: 35px;
      height: 35px;
      border-radius: 20px;
      background-color: #4f8ffc;
      text-align: center;
      color: #fff;
      display: table;

      .iconfont {
        font-size: 18px;
        // 移动端适配 三星8s有兼容问题
        display: table-cell;
        vertical-align:middle;
      }
    }

    .title_text {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      span {
        font-size: 14px;

        &:nth-child(1) {
          font-size: 14px;
          font-weight: 600;
          color: #303031;
        }
        &:nth-child(2) {
          font-size: 12px;
          color: #949495;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 250px;
        }
      }
    }
  }
}
</style>
