import router from "@/router";
import axios from "axios";
import Qs from "qs";
import { getLocalStorage, removeLocalStorage } from "./cookie";

const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 50000,
  withCredentials: false, // true允许/false不允许 跨域请求时发送Cookie   不能于后端的@CrossOrigin共存,  否则会报跨域
  headers: {
    // 'Content-Type':'application/json'
    // "Content-Type": "application/x-www-form-urlencoded",
  },
  // jsonp: 'handleCallback',
  transformRequest: [
    function (data) {
      data = Qs.stringify(data);
      return data;
    },
  ],
});

/**
 * @Description: 对status错误状态的处理
 * @Author: wjw
 * @Date: 2023-03-07 21:47:37
 * @LastEditors: wjw
 * @LastEditTime: Do not edit
 */
const resPonseCode = {
  401() {
    router.push({
      path: "/login",
    });
    this.$toast("请重新登录");
    //token失效清除
    if (getLocalStorage()) {
      removeLocalStorage();
    }
  },
  404() {
    router.push({
      path: "/404",
    });
  },
};

//请求拦截
service.interceptors.request.use(
  (config) => {
    if (config.data === undefined) {
      config.data = {};
    }
    config.data.toKen = getLocalStorage();
    return config;
  },
  (error) => {
    return Promise.reject(error).catch((reason) => {
      return reason;
    });
  }
);

//响应拦截
service.interceptors.response.use(
  (response) => {
    // 正常直接返回
    if (response.status === 200 || response.status === 304) {
      return response.data;
    } else {
      // 对错误进行处理
      if (resPonseCode[response.status]) {
        resPonseCode[response.status]();
      } else {
        // 其他不确定的统一处理
        router.push({
          path: "/errorView",
        });
      }
    }
  },
  (error) => {
    console.log("出错了error==", error);
  }
);

export default service;
