/**
 * @Description: 接收number, 大于10000的转为带万的
 * @Param:
 * @Author: wjw
 * @Date: 2023-03-15 13:55:22
 * @LastEditors: wjw
 * @LastEditTime: Do not edit
 * @param {*} val
 */
export function numberFormat(val) {
  if (10000 <= val) {
    let baseNumber = 10000;
    let count = (val / baseNumber).toFixed(1);

    if (count.charAt(count.length - 1) == 0) {
      count = Number.parseInt(count);
    }
    return count + "万";
  }
  return val;
}

/**
 * @Description: 节流
 * @Param:
 * @Author: wjw
 * @Date: 2023-03-17 01:52:36
 * @LastEditors: wjw
 * @LastEditTime: Do not edit
 * @param {*} func
 * @param {*} delay
 */
export function throttle(func, delay) {
  // 缓存一个定时器
  let timer = null;
  // 这里返回的函数是每次用户实际调用的节流函数
  return function (...args) {
    let hist = this;
    console.log("!timer1",!timer);
    if (!timer) {
      //判断timer是否有值,如果没有则说明定时器不存在即可继续执行
      timer = setTimeout(() => {
        //关
        func.apply(hist, arguments);
        timer = null; //开
        console.log("!timer2",!timer);
      }, delay);
      console.log("!timer3",!timer);
    }
  };
}

/**
 * @Description: 防抖第一次 直接执行
 * @Param: 
 * @Author: wjw
 * @Date: 2023-03-17 17:27:32
 * @LastEditors: wjw
 * @LastEditTime: Do not edit
 * @param {*} fn
 * @param {*} immediate
 * @param {*} delay
 */
export function debounce(fn, immediate = true, delay) {
  let timer;
  let delayTime = delay || 500;
  return function () {
    if (timer) clearTimeout(timer);
    if (immediate) {
      let bool = !timer;
      timer = setTimeout(() => (timer = 0), delayTime);
      return bool && fn.apply(this, [...arguments]);
    }
    timer = setTimeout(() => fn.apply(this, [...arguments]), delayTime);
  };
}


// 计算字符子节长度
export function getStringWidth(val) {
  let len = 0;
  for (let i = 0; i < val.length; i++) {
    let c = val.charCodeAt(i);
    if (c > 255) {
      len += 2;
    } else {
      len += 1;
    }
  }
  return len;
}
