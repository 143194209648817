/**
 * @Description: h5获取cookie的方法
 * @Param:
 * @Author: wjw
 * @Date: 2023-03-04 21:35:57
 * @LastEditors: wjw
 * @LastEditTime: Do not edit
 */

//种植sessionStorage
export const setSessionStorage = (name, value) => {
    window.sessionStorage.setItem(name, JSON.stringify(value));
};

//获取sessionStorage
export const getSessionStorage = (name) => {
    return JSON.parse(window.sessionStorage.getItem(name));
};

//删除sessionStorage
export const removeSessionStorage = (name) => {
    window.sessionStorage.removeItem(name);
};

//种植localStorage
export const setLocalStorage = (name, value) => {
    let Days = 1; //默认一天
    let exp = new Date();
    let expires = exp.getTime() + Days * 24 * 60 * 60 * 1000;
    window.localStorage.setItem(name, JSON.stringify(value, expires));
};

//获取localString
export const getLocalStorage = (name) => {
    let storage = JSON.parse(window.localStorage.getItem(name));
    let time = new Date().getTime();
    let lStorage = {};
    if (storage) {
        if (time < storage.expires) {
            // 没有过期返回对象
            lStorage = storage;
        } else {
            window.localStorage.removeItem(name);
        }
    }
    // 如果cookie不存在或者过期都返回一个空的对象
    return lStorage;
};

//删除localStorage
export const removeLocalStorage = (name) => {
    window.localStorage.removeItem(name);
};
