<template>
  <!-- 标签栏 -->
  <div class="home">
    <div class="home_view_content">
      <!-- <component v-if="!$route.meta.keepAlive" :is="componentId"></component> -->
      <!-- v-if="$route.meta.keepAlive" -->
      <keep-alive>
        <component :is="componentId"></component>
      </keep-alive>
    </div>

    <!-- 底下菜单 -->
    <van-tabbar v-model="active" @change="onChange">
      <van-tabbar-item name="HomePage">
        <div class="tab_img">
          <img
            v-if="active == 'HomePage'"
            src="@/assets/tabbar/shouye_hover.png"
            alt=""
          />
          <img v-else class="tab_img" src="@/assets/tabbar/shouye.png" alt="" />
        </div>
      </van-tabbar-item>
      <van-tabbar-item name="StorePage">
        <div class="tab_img">
          <img
            v-if="active == 'storePage'"
            src="@/assets/tabbar/shop_hover.png"
            alt=""
          />
          <img v-else class="tab_img" src="@/assets/tabbar/shop.png" alt="" />
        </div>
      </van-tabbar-item>
      <van-tabbar-item name="addPage">
        <div class="tab_img">
          <img class="add_img" src="@/assets/tabbar/fabu.png" alt="" />
        </div>
      </van-tabbar-item>
      <van-tabbar-item name="MessagePage">
        <div class="tab_img">
          <img
            v-if="active == 'messagePage'"
            src="@/assets/tabbar/message_hover.png"
            alt=""
          />
          <img
            v-else
            class="tab_img"
            src="@/assets/tabbar/message.png"
            alt=""
          />
        </div>
      </van-tabbar-item>
      <van-tabbar-item name="MePage">
        <div class="tab_img">
          <img
            v-if="active == 'mePage'"
            src="@/assets/tabbar/user_hover.png"
            alt=""
          />
          <img v-else class="tab_img" src="@/assets/tabbar/user.png" alt="" />
        </div>
      </van-tabbar-item>
    </van-tabbar>
    <!-- <div class="put_on_records">
      <a href="https://beian.miit.gov.cn">粤ICP备2021145201号-1</a>
    </div> -->
  </div>
</template>

<script>
import HomePage from "@/views/home/HomePage.vue";
import StorePage from "@/views/StorePage.vue";
import MessagePage from "@/views/MessagePage.vue";
import MePage from "@/views/MePage.vue";
import { Toast } from "vant";

export default {
  name: "HomeView",
  components: {
    HomePage,
    StorePage,
    MessagePage,
    MePage,
  },
  created() {},
  data() {
    return {
      active: "HomePage",
      list: [],
      //MePage
      componentId: "HomePage",
    };
  },
  methods: {
    onChange(name) {
      if (name === "addPage") {
        Toast("开发中");
        // this.$router.push({
        //   path: "/addPage",
        // });
      } else {
        this.componentId = name;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  padding-bottom: 60px;
}

.put_on_records {
  position: fixed;
  bottom: 0px;
  text-align: center;
  margin: 0 auto;
  background-color: #e7e4e4;
  padding: 3px 0;
  width: 100%;
  font-size: 13px;

  a {
    color: #464646;
    vertical-align: sub;
  }
}

.tab_img {
  width: 45px;
  height: 45px;

  img {
    width: 100%;
    height: 100%;
  }
}

.add_img {
}
</style>
