<template>
  <!-- 发现页列表模块 -->
  <div class="discover_page" :style="{ height: iphoneHeight + 'rem' }">
    <van-list
      :immediate-check="false"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div
        class="list_item"
        ref="list_item"
        id="item"
        :style="{
          height: listItemHeight + 'rem',
        }"
      >
        <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
          :style="{
            top: item.top + 'rem',
            left: item.left + 'rem',
          }"
        >
          <div class="show_img">
            <img
              v-if="item.type == 'images'"
              :src="item.image"
              alt="展示图片"
            />
            <img
              v-else-if="item.type == 'video'"
              class=""
              :src="item.videoSrc.poster"
              alt=""
              @click="toVideoPage(index)"
            />
            <div class="is_vide" v-if="item.type == 'video'">
              <span class="iconfont icon-bofang"></span>
            </div>
          </div>
          <div class="item_title">
            {{ item.title }}
          </div>
          <div class="user">
            <div class="user_msg">
              <div class="user_img">
                <img :src="item.touXiang" alt="头像" />
              </div>
              <span>{{ item.userName }}</span>
            </div>
            <div class="count" @click.stop="amountOfConsent(index)">
              <van-icon name="like-o" v-if="item.agreeWithState == 0" />
              <van-icon name="like" color="#FF2442" v-else />
              <span>{{ item.agreeWith | filterAmountOfConsent }}</span>
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { getHomeMsgList } from "@/api/homeApi";
import { numberFormat, debounce, throttle } from "@/utils/util";

export default {
  props: ["active"],
  watch: {
    active: function (newVal, olVal) {
      // console.log("newVal", newVal);
    },
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      listItemHeight: 0, //item容器的高度
      designDrawing: 37.5, //设计图
      waterfallWidthRem: 0, // 每个盒子的宽度 rem
      waterfallWidth: 180, // 每个盒子的宽度
      waterfallCol: 2, // 瀑布流的列数
      waterfallLeft: 5, // 每个盒子的右padding
      waterfallBottom: 8, // 每个盒子的下padding
      waterfallDeviationHeight: [],
      currentPage: 1,
      totalCount: 0,
      selfScrollTop: "", //记录滚动条位置
      iphoneHeight: 0, //内容高度
    };
  },
  filters: {
    filterAmountOfConsent: function (val) {
      if (Number.parseInt(val) >= 1000) {
        return numberFormat(Number.parseInt(val));
      }
      return val;
    },
  },
  created() {
    this.getMsgData();
    this.iphoneHeight = (window.screen.height - 50) / 37.5;
  },
  mounted() {},
  deactivated() {},
  destroyed() {},
  methods: {
    //瀑布流布局
    rankItem() {
      //初始化偏移高度数组
      this.waterfallDeviationHeight = new Array(this.waterfallCol);
      for (let i = 0; i < this.waterfallDeviationHeight.length; i++) {
        this.waterfallDeviationHeight[i] = 0;
      }

      let {
        waterfallWidth,
        waterfallLeft,
        waterfallBottom,
        waterfallDeviationHeight,
      } = this;
      for (let index = 0; index < this.list.length; index++) {
        let minIndex = this.filterMaxAndMin(0);
        this.list[index].top =
          waterfallDeviationHeight[minIndex] / this.designDrawing;
        this.list[index].left =
          (minIndex == 0
            ? waterfallLeft
            : (minIndex + 1) * waterfallLeft + minIndex * waterfallWidth) /
          this.designDrawing; //

        waterfallDeviationHeight[minIndex] +=
          240 + //图片高度
          12 +
          20 + // 头像的高度
          waterfallBottom + //外边界高度
          /*
          限制描述高度  三行78 两行61
          this.getStringWidth 计算出内容高度
          */
          (this.getStringWidth(this.list[index].title) > 40 ? 78 : 61);
      }

      // 设置父容器高度
      this.listItemHeight =
        waterfallDeviationHeight[this.filterMaxAndMin(1)] / this.designDrawing;
    },
    // 计算字符子节长度
    getStringWidth(val) {
      let divWidth = this.waterfallWidth - 22;

      let div = document.createElement("div");
      div.innerHTML = val;
      div.style.width = divWidth + "px";
      div.style =
        `
        width:` +
        divWidth +
        `px;
        z-index:9999999;
        font-size: 15px;
        // visibility:hidden;
        line-height: 20px;
        overflow: hidden; //溢出内容隐藏
        text-overflow: ellipsis; //文本溢出部分用省略号表示
        display: -webkit-box; //特别显示模式
        -webkit-line-clamp: 3; //行数
        line-clamp: 3;
        -webkit-box-orient: vertical; //盒子中内容竖直排
      `;
      div.setAttribute("class", "fontSize");
      document.getElementsByTagName("body")[0].appendChild(div);
      let fontSizeDiv = document.getElementsByClassName("fontSize")[0];
      let fontSizeDivHeight = fontSizeDiv.offsetHeight;
      document.getElementsByTagName("body")[0].removeChild(fontSizeDiv);

      return fontSizeDivHeight;
    },
    /**
     * 0找到最短的
     * 1找到最长的
     * 列并返回下标
     * @returns {number} 下标
     */
    filterMaxAndMin(val) {
      if (val === 0) {
        const min = Math.min.apply(null, this.waterfallDeviationHeight);
        return this.waterfallDeviationHeight.indexOf(min);
      } else {
        const max = Math.max.apply(null, this.waterfallDeviationHeight);
        return this.waterfallDeviationHeight.indexOf(max);
      }
    },
    getMsgData() {
      //获取列表数据
      getHomeMsgList({ currentPage: this.currentPage }).then((res) => {
        this.list = res.list;
        this.totalCount = res.totalCount;
        //瀑布流布局
        this.rankItem();
      });
    },
    onLoad() {
      // 上拉加载
      this.loading = true;
      this.currentPage += 1;

      // 获取列表数据
      getHomeMsgList({ currentPage: this.currentPage }).then((res) => {
        if (res.list.length !== 0) {
          // 加载状态结束
          this.list = this.list.concat(res.list);
          this.totalCount = res.totalCount;
          //瀑布流布局
          this.rankItem();
          this.loading = false;
        } else {
          this.finished = true;
        }
      });
    },
    toVideoPage(index) {
      // 跳转视频播放页
      this.$router.push({
        // path 页面刷新导致 params/query参数丢失
        name: "videoPage",
        params: this.list[index],
      });
    },
    amountOfConsent: debounce(function (index) {
      // 点赞, 节流
      if (this.list[index].agreeWithState == 0) {
        this.list[index].agreeWith = this.list[index].agreeWith + 1;
        this.list[index].agreeWithState = 1;
      } else {
        this.list[index].agreeWith = this.list[index].agreeWith - 1;
        this.list[index].agreeWithState = 0;
      }
    }, 500),
  },
};
</script>

<style lang="less" scoped>
.discover_page {
  background-color: #fbfbf6;
  overflow-y: scroll;
}
.van-list {
  padding-top: 5px;
}

.list_item {
  position: relative;

  .item {
    position: absolute;
    width: 180px;
    border-radius: 5px;
    background-color: #fff;
    overflow: hidden;
    box-sizing: content-box;
    .show_img {
      height: 240px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }

      .is_vide {
        position: absolute;
        top: 12px;
        right: 12px;
        width: 20px;
        height: 20px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        .iconfont {
          color: #fff;
          font-size: 12px;
          transform: scale(0.7);
        }
      }
    }

    .item_title {
      font-size: 15px;
      margin: 11px 11px 8px 11px;
      line-height: 20px;
      word-break: break-all;
      text-ovelow: -o-ellipsis-lastline;
      overflow: hidden; //溢出内容隐藏
      text-overflow: ellipsis; //文本溢出部分用省略号表示
      display: -webkit-box; //特别显示模式
      -webkit-line-clamp: 3; //行数
      line-clamp: 3;
      -webkit-box-orient: vertical; //盒子中内容竖直排
    }

    .user {
      padding: 0 8px;
      padding-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .user_msg {
        display: flex;
        align-items: center;
        .user_img {
          width: 20px;
          height: 20px;
          border-radius: 10px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }

        & > span {
          color: #999;
          font-size: 12px;
          margin-left: 6px;
          width: 85px;
          white-space: nowrap; // 强制一行显示
          overflow: hidden; // 超出隐藏
          text-overflow: ellipsis; // 省略号
        }
      }

      .count {
        color: #999;
        font-size: 12px;

        & > span {
          margin-left: 3px;
        }
      }
    }
  }
}
</style>
